import React, { Component } from 'react';
import MUIDataTable from "mui-datatables";
import Navbar from './navbar.component';
import config from '../keys.json'; 
import axios from 'axios';
import { CircularProgress, Box } from '@material-ui/core';
import CustomToolbarSelect from "./custom-datatable-select-toolbar";
import swal from 'sweetalert';
import xlsx from 'xlsx';

import urlCustom from '../url'
const apiUrl = urlCustom.getUrl(window)

export default class UserList extends Component {
  constructor(props) {
    super(props);

    
    this.onFileChange = this.onFileChange.bind(this);
    this.handleBulkUser = this.handleBulkUser.bind(this);
    this.state = {
      users: [],
      nik:'',
      loading:false,
      loadingAwal:true,
      id:'',
      role:'',
      rolex:'',
      selectedFile:null,
      childID:[]
    };
  }
  componentDidMount() {
    
    // console.log(window.location.hostname)
    // console.log(window.location.port)
    // console.log(window.location.protocol)
    // console.log(urlCustom.getUrl(window));
      this.getkomplit(this.props.match.params.id)
      .then(()=>{
        this.populateUser();
        this.setState({
          loadingAwal:false
        })  
      }) 
  }

  onFileChange(event) {
    this.setState({
      selectedFile: event.target.files[0]
    })
    console.log(event.target.files[0])
  }

  async getkomplit(id){ 
    const hasil = await axios.get(apiUrl+"/users/"+id)
      .then(res => {
        if(res!=null){
        this.setState({
          nik : res.data.nik,
          role:res.data.role,
          rolex:res.data.rolex,
          id:res.data.id,
          childID:res.data.childUser
        });
      }
      else {
        alert('User tidak ditemukan, mohon login kembali');
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push('/login');
      }
      });
    }
  populateUser(){
    axios.get(apiUrl+'/users')
      .then(response => {
        let dataPopul = response.data;
        let dataFilter=[]
        dataPopul.forEach(data => {
          if(this.state.role=='adminairlabsakti' && (this.state.rolex=='superadminsakti' || this.state.childID.includes(data.nik))){
            dataFilter.push(data)
          }
          else if(this.state.role=='adminbatamnuansa'){
            if(data.perusahaan=="PT INFINITI NUANSA INTERNATIONA"){
              dataFilter.push(data)
            }
          }
        });
        this.setState({
          users:dataFilter
        })
      })
      .catch((error) => {
        console.log(error);
      })
  }
  handleClick(data){
        // console.log(data);
  }
  
  
  handleBulkUser(e){
    e.preventDefault();
    this.setState({
        loading:true
    })
    var f = this.state.selectedFile;
    var reader = new FileReader();
    let impor = null;
    reader.onload= (e)=>
    {
        var data = e.target.result;
        let teks =''
        let flagWarning = false
        let readData = xlsx.read(data,{type:'binary'});
        const wsname = readData.SheetNames[0];
        impor = readData.Sheets[wsname];
        const dataParse = xlsx.utils.sheet_to_json(impor, {header:1});
        for (let i = 1; i < dataParse.length; i++) {
            const nik = dataParse[i][0].toString()
            const nama = dataParse[i][1].toString()
            const perusahaan = dataParse[i][2].toString()
            const password = dataParse[i][3].toString()
            const role=dataParse[i][4].toString()
            const req = {
                nik,
                nama,
                perusahaan,
                password,
                role
            }
            axios.post(apiUrl+"/users/add",req)
                .then((res)=>{
                  console.log(res)
                })
        }
        swal("ISR","Upload User sukses","success")
        this.setState({
            loading:false
        })  
    }
    reader.readAsBinaryString(f);
  }

  render() {
    const isAdmin = this.state.role=='adminairlabsakti';
    const isAdminBatam = this.state.role=='adminbatamnuansa';
    const columns = [
        {
            name:"id",
            options:{
                display:false,
                filter:false
            }
        },
        {
          name:"nik",
          label:'NIK'
        },
        {
          name:"nama",
          label:'Nama'
        },
        {
          name:"perusahaan",
          label:'Perusahaan'
        },
        
        {
          name:"id",
          label:'Password',
          options:{
            customBodyRender:(value,tableMeta,updateValue)=>{
                  return(
                    <a href={'/passres/'+value}>Reset Password</a>
                  )
            },
            filter:false
          }
        }
        
    ];
    const options = {
        viewColumns:false,
        print:false,
        filter:isAdmin,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
          <CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} page="users/"/>
        ),
        onRowClick: (rowData,rowState)=>{this.handleClick(rowData)}
        }
    return (
      <div>
           <div>
        <Navbar userid={this.state.id} nik={this.state.nik} role={this.state.role} rolex={this.state.rolex}/>
        </div>
          {this.state.loadingAwal && <><CircularProgress size={28}/>Loading...Mohon Tunggu</>}
          <MUIDataTable
            title={"Employee List"}
            columns={columns}
            data={this.state.users}
            options={options}
          />
           <form onSubmit={this.handleBulkUser}>
        <div className="form-group">
        <input type="file" name="file" className="btn btn-primary" onChange={this.onFileChange}/>
        </div>
        <div className="form-group">
            <input type="submit" value="Submit Users" className="btn btn-primary" style={{marginRight:20}}/>
            </div>
        </form>
      </div>
    )
  }
}