import React, { Component } from "react";
import axios from "axios";
import Navbar from "./navbar.component";
import config from "../keys.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import auth from "../auth";
import { withRouter } from "react-router-dom";
import jwt, { decode } from "jsonwebtoken";
import { CircularProgress, Box } from "@material-ui/core";
import swal from "sweetalert";
import Contoh from "../assets/Contoh.JPG";
import ContohSmall from "../assets/Contoh-small.JPG";

import { ResponsiveImage, ResponsiveImageSize } from "react-responsive-image";

import urlCustom from "../url";
const apiUrl = urlCustom.getUrl(window);

export default class CreateExpense extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef;
    this.onChangeField = this.onChangeField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onprogress = this.onprogress.bind(this);
    this.state = {
      id: "",
      nik: "",
      nama: "",
      tgl: new Date(),
      tbt: 0,
      tip: 0,
      tll: 0,
      speedo: 0,
      loading: false,
      loadingawal: true,
      loadingdate: false,
      komplit: [],
      bbt: 0,
      bip: 0,
      role: "user",
      selectedFile: null,
      namaFile: [],
      progressUpload: 0,
    };
  }
  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token != null) {
      const kirim = {
        tokens: token,
        keterangan: "xxx",
      };
      const decoded = jwt.verify(kirim.tokens, config.secret);
      return decoded;
    }
  }

  async getkomplit(id) {
    const hasil = await axios.get(apiUrl + "/users/" + id).then((res) => {
      // console.log(res)
      if (res.data != null) {
        this.setState({
          komplit: res.data,
          id: res.data.id,
          nama: res.data.nama,
          nik: res.data.nik,
          role: res.data.role,
          loadingawal: false,
        });
        // if(this.state.role!='adminairlabsakti'){
        this.getBudget();
        // }
      } else {
        alert("User tidak ditemukan, mohon login kembali");
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push("/login");
      }
    });
  }
  async getBudget() {
    var tgl = this.state.tgl;
    var today = new Date();
    let periodCode = 0;
    let tahun = tgl.getFullYear().toString();
    if (tgl.getDate() <= 20) {
      periodCode = tgl.getMonth() + 1;
    } else {
      if(tgl.getMonth()==11){
        periodCode=1
        tahun=(Number(tahun)+1).toString()
      }
      else{
        periodCode = tgl.getMonth() + 2;
      }
    }
    const req = {
      filterField1: "nik",
      filter1: this.state.nik,
      filterField2: "periodCode",
      filter2: periodCode,
      filterField3: "tahun",
      filter3: tahun,
    };
    await axios.post(apiUrl + "/budget/find", req).then((res) => {
      if (res.data == "Budget not found") {
        swal(
          "Error",
          "Budget belum dimasukkan, silahkan hubungi admin",
          "error"
        );
        this.setState({
          loading: false,
          loadingdate: false,
          bbt: 0,
          bip: 0,
        });
      } else {
        this.setState({
          bbt: res.data.budgets.bbt,
          bip: res.data.budgets.bip,
          loading: false,
          loadingdate: false,
        });
      }
    });
  }
  componentDidMount() {
    // console.log(this.state)
    let stat = auth.isAuthenticated();
    this.setState({ isLoggedIn: stat });
    const dariToken = this.parseTokenFunc();
    if (dariToken != null) {
      this.setState({
        id: dariToken.sub,
      });
      this.getkomplit(dariToken.sub);
      // this.getBudget();
    }
  }
  onChangeField(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  onDateChange(tgl) {
    this.setState(
      {
        tgl: tgl,
        loadingdate: true,
      },
      () => {
        this.getBudget();
      }
    );
  }
  onFileChange(event) {
    this.setState({
      selectedFile: event.target.files,
    });
  }

  onprogress(val) {
    this.setState({
      progressUpload: val,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const teks =
      "Mohon pastikan kembali data anda :\n" +
      "Nama : " +
      this.state.nama +
      "\nNIK  : " +
      this.state.nik +
      "\nTunjangan BBM & Tol : " +
      this.state.tbt +
      "\nTunjangan Internet & Pulsa : " +
      this.state.tip;
    console.log(this.state);
    const expense = {
      date: this.state.tgl,
      nik: this.state.nik,
      tbt: this.state.tbt != 0 ? this.state.tbt.replace(/,/g, "") : 0,
      tip: this.state.tip != 0 ? this.state.tip.replace(/,/g, "") : 0,
      speedo: this.state.speedo != 0 ? this.state.speedo.replace(/,/g, "") : 0,
      tll: this.state.tll != 0 ? this.state.tll.replace(/,/g, "") : 0,
    };
    const budget = {
      bbt: this.state.bbt,
      bip: this.state.bip,
    };
    if ((this.state.role=="user") && Number(expense.tbt) + Number(expense.tip) > budget.bip) {
      swal("Error", "Klaim Melebihi Budget", "error");
      return false;
    }
    if ((this.state.role!="user") && Number(expense.tip) > budget.bip){
      swal("Error","Klaim Melebihi Budget","error");
      return false;
    }
    if (this.state.selectedFile == null) {
      swal("Error", "Tidak ada file yang dipilih", "error");
    } else if (this.state.selectedFile.length == 0) {
      swal("Error", "Tidak ada file yang dipilih", "error");
    } else if (this.state.speedo == 0 && this.state.tbt != 0) {
      swal("Error", "Angka Odometer harus diisi", "error");
    } else {
      swal({
        title: "Konfirmasi",
        text: teks,
        icon: "warning",
        buttons: true,
      }).then((hasil) => {
        if (hasil) {
          this.setState({
            loading: true,
          });
          var tgl = this.state.tgl;
          var today = new Date();
          let periodCode = 0;
          let tahun = tgl.getFullYear();
          if (tgl.getDate() <= 20) {
            periodCode = tgl.getMonth() + 1;
          } else {
            if(tgl.getMonth()==11){
              periodCode=1
              tahun=(Number(tahun)+1).toString()
            }
            else{
              periodCode = tgl.getMonth() + 2;
            }
          }

          var konfig = {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log(percentCompleted);
              this.onprogress(percentCompleted);
            },
          };

          const formData = new FormData();
          for (var x = 0; x < this.state.selectedFile.length; x++) {
            formData.append("file", this.state.selectedFile[x]);
          }
          axios
            .post(apiUrl + "/upload", formData, konfig)
            .then((res) => {
              console.log(res.data);
              var namanama = [];
              for (var x = 0; x < res.data.length; x++) {
                namanama.push("uploads/" + res.data[x].filename);
              }
              const expense = {
                date: this.state.tgl,
                nik: this.state.nik,
                tbt: this.state.tbt != 0 ? this.state.tbt.replace(/,/g, "") : 0,
                tip: this.state.tip != 0 ? this.state.tip.replace(/,/g, "") : 0,
                speedo:
                  this.state.speedo != 0
                    ? this.state.speedo.replace(/,/g, "")
                    : 0,
                tll: this.state.tll != 0 ? this.state.tll.replace(/,/g, "") : 0,
                periodCode: periodCode,
                namaFile: namanama,
                tahun:tahun,
                status: "Pending",
                remark: "Menunggu Approve Admin",
              };
              const budget = {
                bbt: this.state.bbt,
                bip: this.state.bip,
              };
              console.log(expense.tbt)
              console.log(expense.tip)
              console.log(budget.bip)
              if ((this.state.role=="user") && Number(expense.tbt) + Number(expense.tip) > budget.bip) {
                swal("Error", "Klaim yang dimasukkan melebihi budget", "error");
                this.setState({
                  loading: false,
                });
               }
              else if  ((this.state.role!="user")&& Number(expense.tip)>budget.bip){
                swal("Error","Klaim yang dimasukkan melebihi budget","error");
                this.setState({
                  loading:false,
                });} else {
                axios
                  .post(apiUrl + "/expense/add", expense)
                  .then((res) => {
                    // const ubt = budget.bbt - expense.tbt;
                    const uip = budget.bip - expense.tip - expense.tbt;
                    const kirim = {
                      bbt: budget.bbt,
                      bip: budget.bip,
                      tbt: expense.tbt,
                      tip: expense.tip,
                      tll: expense.tll,
                      speedo: expense.speedo,
                      expense: expense.date,
                      nik: expense.nik,
                      periodCode: periodCode,
                      // value: ubt,
                      value2: uip,
                      filterField1: "nik",
                      filter1: this.state.nik,
                      filterField2: "periodCode",
                      filter2: periodCode,
                      filterField3: "tahun",
                      filter3: tahun,
                      namaFile: expense.namaFile,
                    };
                    // axios.post(apiUrl+'/budget/update/bbt/bip',kirim)
                    // .then(()=>{
                    //   this.getBudget();
                    // })

                    swal("ISR", "Submit sukses", "success");
                    this.setState({
                      loading: false,
                    });
                  })
                  .catch((err) => {
                    swal("Error", "Error: " + err, "error");
                    this.setState({
                      loading: false,
                    });
                  });
                this.setState({
                  tbt: 0,
                  tip: 0,
                });
              }
            })
            .catch((err) => {
              this.setState({
                loading: false,
              });
              swal("Error", "Upload File Error", "error");
            });
        }
      }); //akhir then pertama onSubmit
    }
  } //akhir onSubmit

  render() {
    // loadProgressBar();
    return (
      <div>
        <Navbar
          userid={this.state.id}
          nik={this.state.nik}
          role={this.state.role}
        />
        <div>
          <h3>{"Selamat datang, " + this.state.komplit.nama}</h3>
          Pastikan data yang anda masukkan sudah benar
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>NIK: </label>
              <input
                type="text"
                name="nik"
                required
                className="form-control"
                value={this.state.nik}
                onChange={this.onChangeField}
                disabled
              />
              <label>Nama: </label>
              <input
                type="text"
                name="nama"
                required
                className="form-control"
                value={this.state.nama}
                onChange={this.onChangeField}
                disabled
              />

              {/* {this.state.role != "management" &&
                this.state.role != "adminairlabsakti" &&
                this.state.role != "reviewer" && (
                  // <>
                  //   <label>{"Sisa Budget BBM & Tol"}: </label>
                  //   <NumberFormat
                  //     className="form-control"
                  //     thousandSeparator={true}
                  //     prefix={""}
                  //     name="bbt"
                  //     isNumericString={true}
                  //     onChange={this.onChangeField}
                  //     value={this.state.bbt}
                  //     disabled
                  //   />
                  // </>
                )} */}
              {this.state.role == "user" &&
              <label>{"Sisa Budget"}: </label>}
              {this.state.role != "user" && 
		<label>{"Sisa Budget Telekomunikasi"}</label>}
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                isNumericString={true}
                prefix={""}
                name="bip"
                onChange={this.onChangeField}
                value={this.state.bip}
                disabled
              />

              <label>{"Tanggal :    "}</label>
              <div className="form-group">
                <DatePicker
                  selected={this.state.tgl}
                  onChange={this.onDateChange}
                />
                {this.state.loadingdate && (
                  <>
                    <CircularProgress size={28} />
                    Loading...Mohon Tunggu
                  </>
                )}
              </div>
              <label>{"Tunjangan BBM & Tol"}: </label>
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={""}
                name="tbt"
                isNumericString={true}
                onChange={this.onChangeField}
                value={this.state.tbt}
              />

              <label>{"Tunjangan Internet & Pulsa"}: </label>
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={""}
                name="tip"
                isNumericString={true}
                onChange={this.onChangeField}
                value={this.state.tip}
              />
              {(this.state.role == "management" ||
                this.state.role == "adminairlabsakti" ||
                this.state.role == "reviewer") && (
                <>
                  <label>{"Tunjangan Lain-Lain"}: </label>
                  <NumberFormat
                    className="form-control"
                    thousandSeparator={true}
                    prefix={""}
                    name="tll"
                    isNumericString={true}
                    onChange={this.onChangeField}
                    value={this.state.tll}
                  />
                </>
              )}
              <label>{"Angka Odometer"}: </label>
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={""}
                name="speedo"
                isNumericString={true}
                onChange={this.onChangeField}
                value={this.state.speedo}
              />
            </div>
            <div className="form-group">
              <input
                type="file"
                name="file"
                className="btn btn-primary"
                multiple
                onChange={this.onFileChange}
              />
            </div>
            <div className="form-group">
              <b>
                (Wajib Upload : struk bbm, tiket tol, pulsa dan speedo meter
                mobil)
              </b>
            </div>
            {this.state.loading && (
              <>
                <CircularProgress size={28} />
                {"    " +
                  this.state.progressUpload +
                  "% Loading...Mohon Tunggu"}
              </>
            )}

            <div className="form-group">
              <input
                type="submit"
                value="Submit Data"
                className="btn btn-primary"
                style={{ marginRight: 20 }}
              />
              <ResponsiveImage>
                <ResponsiveImageSize default minWidth={0} path={ContohSmall} />
                <ResponsiveImageSize minWidth={768} path={Contoh} />
                <ResponsiveImageSize minWidth={1100} path={Contoh} />
              </ResponsiveImage>
              {/* <img src={Contoh}/> */}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
