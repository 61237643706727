const isLoggedIn = () => {
    return localStorage.getItem('TOKEN_KEY') != null;
}
const isAdmin = () =>{
    
}
class Auth{
    constructor(){
        this.authenticated = false
        this.authenticatedAdmin = false
    };
    // async getkomplit(id){ 
    //     const hasil = await axios.get(config.baseURL+"users/"+id)
    //       .then(res => {
    //         this.setState({
    //           komplit: res.data,
    //           nama : res.data.nama,
    //           nik : res.data.nik
    //         });
    //         console.log(res.data);
    //       });
    //     }
        // parseTokenFunc() {
        //     const token = localStorage.getItem("TOKEN_KEY");
        //     if (token!=null) {
        //       const kirim = {
        //         tokens: token,
        //         keterangan:"xxx"
        //       };
        //       console.log(kirim);
        //       const decoded = jwt.verify(kirim.tokens,config.secret);
        //       console.log(decoded)
        //       return decoded;  
        //     }
        //   }
    login(cb){
        this.authenticated = true;
        cb();
    };

    logout(cb){
        this.authenticated = false;
        cb();
    }

    isAuthenticated(){
        this.authenticated = isLoggedIn();
        return this.authenticated;
    }
    isAdmin(){
        this.authenticatedAdmin = isAdmin();
        return this.authenticatedAdmin;
    }


}

export default new Auth()