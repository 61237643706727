class URLCustom {
  constructor() {
    this.url = "xxx";
  }
  getUrl(terima) {
     return terima.location.origin;
    // return ("http://172.16.100.117");
    //return "http://localhost";
    // return "https://isr.airlab.id";
  }
}

export default new URLCustom();
