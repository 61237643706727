import React, { Component, Fragment } from "react";
import axios from "axios";
import Navbar from "./navbar.component";
import config from "../keys.json";
import "react-datepicker/dist/react-datepicker.css";
import auth from "../auth";
import { withRouter } from "react-router-dom";
import jwt, { decode } from "jsonwebtoken";
import { CircularProgress, Box, Button, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import CustomToolbarSelect from "./custom-datatable-select-toolbar";
import MUIDataTable from "mui-datatables";

import urlCustom from "../url";
import swal from "sweetalert";
const apiUrl = urlCustom.getUrl(window);
//  const apiUrl = 'http://localhost'

const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(
      new maps.LatLng(place.geometry.location.lat, place.geometry.location.lng)
    );
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, "idle", () => {
    maps.event.addDomListener(window, "resize", () => {
      map.fitBounds(bounds);
    });
  });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

const bg = require("../assets/Contoh.JPG");

export default class GroupUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nik: "",
      nama: "",
      tgl: new Date(),
      tbt: 0,
      tip: 0,
      loading: false,
      loadingawal: true,
      loadingdate: false,
      komplit: [],
      bbt: 0,
      bip: 0,
      role: "",
      rolex: "",
      selectedAdmin: "",
      namaFile: [],
      users: [],
      childID: [],
      childUsers: [],
      allUsers: [],
      admins: [],
    };
    this.onChangeField = this.onChangeField.bind(this);
  }

  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token != null) {
      const kirim = {
        tokens: token,
        keterangan: "xxx",
      };
      const decoded = jwt.verify(kirim.tokens, config.secret);
      return decoded;
    }
  }
  getAdmin() {
    return this.state.admins.map((x) => {
      return <option value={x.id}>{x.nama}</option>;
    });
  }

  onChangeField(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    axios.get(apiUrl + "/users/" + value).then((res) => {
      this.setState({
        adminID: value,
        adminChildID: res.data.childUser,
      });
      if (res.data.childUser == []) {
        swal("Error", "Admin tidak memiliki group", "error");
      } else {
        const req = res.data.childUser;
        axios.post(apiUrl + "/users/getChildUsers", req).then((res) => {
          console.log(res);
          this.setState({
            selectedAdmin: value,
            childUsers: res.data,
          });
        });
      }
    });
  }

  async getkomplit(id) {
    const hasil = await axios.get(apiUrl + "/users/" + id).then((res) => {
      if (res.data != null) {
        this.setState({
          komplit: res.data,
          id: res.data.id,
          nama: res.data.nama,
          nik: res.data.nik,
          role: res.data.role,
          rolex: res.data.rolex,
          childID: res.data.childUser,
          loadingawal: false,
        });
      } else {
        alert("User tidak ditemukan, mohon login kembali");
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push("/login");
      }
    });
  }

  componentDidMount() {
    console.log(apiUrl);
    let stat = auth.isAuthenticated();
    this.setState({ isLoggedIn: stat });
    const dariToken = this.parseTokenFunc();
    if (dariToken != null) {
      this.setState({
        id: dariToken.sub,
      });
      this.getkomplit(dariToken.sub).then(() => {
        axios.get(apiUrl + "/users/getAdmin").then((res) => {
          this.setState({
            admins: res.data,
          });
          axios.get(apiUrl + "/users/getNonAdmin").then((res) => {
            this.setState({
              allUsers: res.data,
            });
          });
        });
      });
      // this.getBudget();
    }
  }

  getLoc() {
    axios.get("https://www.cloudflare.com/cdn-cgi/trace").then((res) => {
      let ipAddr = res.data.split("ts=")[0].split("ip=")[1];
      axios
        .get(
          "http://api.ipstack.com/" +
            ipAddr +
            "?access_key=b369318703f7b1e96d71a5247ad8e41c"
        )
        .then((res) => {
          // console.log(res);
          this.setState({
            lat: res.data.latitude,
            long: res.data.longitude,
          });
        });
    });
  }

  render() {
    const columns = [
      {
        name: "id",
        options: {
          display: false,
          filter: false,
        },
      },
      {
        name: "nik",
        label: "NIK",
      },
      {
        name: "nama",
        label: "Nama",
      },
      {
        name: "perusahaan",
        label: "Perusahaan",
      },
    ];
    const options = {
      viewColumns: false,
      print: false,
      filter: true,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          page="selectedUser"
          role={this.state.role}
          id={this.state.adminID}
          childUser={this.state.adminChildID}
        />
      ),
    };
    const options2 = {
      viewColumns: false,
      print: false,
      filter: true,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          page="allUser"
          role={this.state.role}
          id={this.state.adminID}
          childUser={this.state.adminChildID}
        />
      ),
    };
    return (
      <div className="container">
        <Navbar
          userid={this.state.id}
          nik={this.state.nik}
          role={this.state.role}
          rolex={this.state.rolex}
        />
        <div>
          <h3>{"Selamat datang Administrator, " + this.state.komplit.nama}</h3>

          <form id="regform" onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>Pilih Admin: </label>
              <select
                name="selectedAdmin"
                required
                className="form-control"
                form="regform"
                value={this.state.selectedAdmin}
                onChange={this.onChangeField}
              >
                {this.getAdmin()}
              </select>
            </div>

            <div className="form-group">
              <MUIDataTable
                title={"Managed Users"}
                columns={columns}
                data={this.state.childUsers}
                options={options}
              />
              <MUIDataTable
                title={"All Users"}
                columns={columns}
                data={this.state.allUsers}
                options={options2}
              />
            </div>

            <div className="form-group">
              {this.state.loading && (
                <>
                  <CircularProgress size={28} />
                  Loading...Mohon Tunggu
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
