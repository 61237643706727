import React, { Component,Fragment } from 'react';
import axios from 'axios';
import Navbar from "./navbar.component";
import config from "../keys.json";
import "react-datepicker/dist/react-datepicker.css";
import auth from '../auth';
import {withRouter} from 'react-router-dom';
import jwt, { decode } from 'jsonwebtoken';
import { CircularProgress, Box, Button,IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';



import urlCustom from '../url'
const apiUrl = urlCustom.getUrl(window)
//  const apiUrl = 'http://localhost'

const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach((place) => {
    bounds.extend(new maps.LatLng(
      place.geometry.location.lat,
      place.geometry.location.lng,
    ));
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds);
    });
  });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places);
  // Fit map to bounds
  map.fitBounds(bounds);
  // Bind the resize listener
  bindResizeListener(map, maps, bounds);
};

const bg = require(('../assets/Contoh.JPG'));

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:'',
      nik: '',
      nama:'',
      tgl: new Date(),   
      tbt:0,
      tip:0,
      loading:false,
      loadingawal:true,
      loadingdate:false,
      komplit:[],
      bbt:0,
      bip:0,
      role:'',
      rolex:'',
      selectedFile : null,
      namaFile:[],
      progressUpload:0,
      lat:'',
      long:'',
      places:[]
    }
  }
  
  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token!=null) {
      const kirim = {
        tokens: token,
        keterangan:"xxx"
      };
      const decoded = jwt.verify(kirim.tokens,config.secret);
      return decoded;  
    }
  }

  async getkomplit(id){ 
    const hasil = await axios.get(apiUrl+"/users/"+id)
      .then(res => {
        if(res.data!=null){
        this.setState({
          komplit: res.data,
          id:res.data.id,
          nama : res.data.nama,
          nik : res.data.nik,
          role : res.data.role,
          rolex : res.data.rolex,
          loadingawal:false
        });
      }
      else {
        alert('User tidak ditemukan, mohon login kembali');
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push('/login');
      }
      });
    }
  componentDidMount() {
      console.log(apiUrl)
      let stat = auth.isAuthenticated();
      this.setState(
        {isLoggedIn : stat}
      )
      const dariToken = this.parseTokenFunc();
      if(dariToken!=null){
        this.setState({
          id:dariToken.sub
        })
        this.getkomplit(dariToken.sub);
        // this.getBudget();
      }
     }
    
  getLoc(){
    axios.get('https://www.cloudflare.com/cdn-cgi/trace')
      .then(res =>{
        let ipAddr=res.data.split("ts=")[0].split("ip=")[1]
        axios.get("http://api.ipstack.com/"+ipAddr+"?access_key=b369318703f7b1e96d71a5247ad8e41c")
          .then((res)=>{
            console.log(res);
            this.setState({
              lat:res.data.latitude,
              long:res.data.longitude
            })
          })
      })
  }


  render() {
    return(

   
     
      <div classname='container' >
          <Navbar userid={this.state.id} nik={this.state.nik} role={this.state.role} rolex={this.state.rolex}/>
          <div style={{ justifyContent:'center', alignItems:'center',textAlign:'center'}}>
        <h3>{"Selamat datang Administrator, " + this.state.komplit.nama}</h3>
        <h5>Silahkan pilih salah satu menu di atas</h5>
        </div>
        <br/>
        {/* <Button variant="contained" color="primary" onClick={()=>{this.getLoc()}}>Tes</Button> */}
        <br/>
        {/* {"Lat : "+this.state.lat}
        <br/>
        {"Long : "+this.state.long} */}

  {/* 
        <br/>

                <Button variant="contained" color="primary" href={"/list/expense/"+this.state.id}>Daftar Pengeluaran</Button>
       
        <br/>
        <div>
       
                {this.state.isLoggedIn && (this.state.role!="adminairlabsakti") && (<><Button variant="contained" color="primary" href='/'>Input Pengeluaran</Button></>)}
                </div>
        <br/>
        <div>
                {(this.state.role=="adminairlabsakti") && (<><Button variant="contained" color="primary" href={"/list/budget/"+this.state.id}>Daftar Budget</Button></>)}
                </div>
        <br/>
        <div>
                {(this.state.role=="adminairlabsakti") && (<><Button variant="contained" color="primary" href={"/list/user/"+this.state.id}>Daftar User</Button></>)}
                </div>
        <br/>
        <div>
                {(this.state.role=="adminairlabsakti") && (<><Button variant="contained" color="primary" href={"/add/budget/"+this.state.id}>Add Budget</Button></>)}
</div> */}
      </div> 
    )
   }
  }