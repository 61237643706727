import React, { Component } from "react";
import { Link } from "react-router-dom";
import auth from "../auth";
import { withRouter } from "react-router-dom";
import jwt, { decode } from "jsonwebtoken";

const config = require("../keys.json");

const ButtonLogin = withRouter(({ history }) => (
  <button
    type="button"
    onClick={() => {
      auth.logout(() => {
        localStorage.removeItem("TOKEN_KEY");
      });
      history.push("/");
    }}
  >
    Logout
  </button>
));
const ButtonReg = withRouter(({ history }) => (
  <button
    type="button"
    onClick={() => {
      history.push("/register");
    }}
  >
    Register
  </button>
));

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: "",
      isLoggedIn: false,
      dat: [],
      komplit: [],
    };
    // this.handleLogout = this.handleLogout.bind(this);
  }
  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token != null) {
      const kirim = {
        tokens: token,
        keterangan: "xxx",
      };
      // console.log(kirim);
      const decoded = jwt.verify(kirim.tokens, config.secret);
      // console.log(decoded)
      return decoded;
    }
  }

  componentDidMount() {
    let stat = auth.isAuthenticated();
    this.setState({
      isLoggedIn: stat,
      userid: this.props.userid,
    });
  }
  render() {
    return (
      <nav className="navbar navbar-dark bg-dark navbar-expand-lg">
        <Link to="/" className="navbar-brand">
          Infiniti Receipt System
        </Link>
        <div className="collpase navbar-collapse">
          <ul className="navbar-nav mr-auto"></ul>
        </div>

        {this.state.isLoggedIn && (
          <>
            <Link to={"/list/expense/" + this.props.userid}>
              <button>Daftar Pengeluaran</button>
            </Link>
          </>
        )}
        {this.state.isLoggedIn && (
          <>
            <Link to={"/"}>
              <button>Input Pengeluaran</button>
            </Link>
          </>
        )}
        {this.state.isLoggedIn &&
          (this.props.role == "adminairlabsakti" ||
            (this.props.role == "adminbatamnuansa" &&
              this.props.role != "reviewer")) && (
            <>
              <Link to={"/list/budget/" + this.props.userid}>
                <button>Daftar Budget</button>
              </Link>
            </>
          )}
          {this.state.isLoggedIn &&
            (this.props.role == "adminairlabsakti" ||
              (this.props.role == "adminbatamnuansa" &&
                this.props.role != "reviewer")) && (
              <>
                <Link to={"/reportSofi/" + this.props.userid}>
                  <button>Report SOFI</button>
                </Link>
              </>
            )}
        {this.state.isLoggedIn &&
          (this.props.role == "adminairlabsakti" ||
            (this.props.role == "adminbatamnuansa" &&
              this.props.role != "reviewer")) &&
          this.props.rolex == "superadminsakti" && (
            <>
              <Link to={"/usergroup/" + this.props.userid}>
                <button>Admin Group</button>
              </Link>
            </>
          )}
        {this.state.isLoggedIn &&
          (this.props.role == "adminairlabsakti" ||
            (this.props.role == "adminbatamnuansa" &&
              this.props.role != "reviewer")) && (
            <>
              <Link to={"/list/user/" + this.props.userid}>
                <button>Daftar User</button>
              </Link>
            </>
          )}
        {this.state.isLoggedIn &&
          (this.props.role == "adminairlabsakti" ||
            (this.props.role == "adminbatamnuansa" &&
              this.props.role != "reviewer")) && (
            <>
              <Link to={"/add/budget/" + this.props.userid}>
                <button>Upload Budget</button>
              </Link>
            </>
          )}
        {this.state.isLoggedIn && (
          <>
            <Link to={"/passres/" + this.props.userid}>
              <button>Change Password</button>
            </Link>
          </>
        )}
        {this.state.isLoggedIn && (
          <>
            <ButtonLogin />
          </>
        )}
      </nav>
    );
  }
}
