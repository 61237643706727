import React, { Component } from 'react';
import axios from 'axios';
import {CircularProgress} from '@material-ui/core';
import Navbar from "./navbar.component";
import config from "../keys.json";
import swal from 'sweetalert';

import urlCustom from '../url'
const apiUrl = urlCustom.getUrl(window)

export default class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.onChangeField = this.onChangeField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      password: '',
      password2:'',
      nama:'',
      nik:'',
      perusahaan:'PT Citra Langgeng Sentosa',
      loading:false
    }
  }

  onChangeField(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
        [name]:value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    if(this.state.password === this.state.password2){
    this.setState({
      loading:true
    })
    const user = {
      password: this.state.password,
      nama: this.state.nama,
      nik: this.state.nik,
      perusahaan :this.state.perusahaan,
      role:'user'
    }

    console.log(user);

    axios.post(apiUrl+'/users/add', user)
      .then(
          res => {
              console.log(res.data);
              this.setState({
                password: '',
                nama:'',
                nik:'',
                perusahaan:'',
                loading:false
              });
              swal("ISR",'Pendaftaran berhasil, silahkan login',"success");
              this.props.history.push("/login")
          }
      
      )
      .catch(err =>{
        console.log(err.response.data);
        swal("Error",err.response.data,"error");
        this.setState({
          password: '',
          nama:'',
          nik:'',
          perusahaan:'',
          loading:false
        });
      })

        }
        else{
          swal("Error","Password tidak sama","error");
        }
  }

  render() {
    return (
      <div>
        <div>
        <Navbar/>
        </div>
        <h3>Create New User</h3>
        <form id = "regform" onSubmit={this.onSubmit}>
          <div className="form-group"> 
            <label>NIK: </label>
            <input  type="text"
                name = 'nik'
                required
                className="form-control"
                value={this.state.nik}
                onChange={this.onChangeField}
                />
            <label>Nama: </label>
            <input  type="text"
                name = 'nama'
                required
                className="form-control"
                value={this.state.nama}
                onChange={this.onChangeField}
                />
       
       <label>Perusahaan: </label>
            <select
                name = 'perusahaan'
                required
                className="form-control"
                form = "regform"
                value={this.state.perusahaan}
                onChange={this.onChangeField}
                >
                  <option value = "PT Citra Langgeng Sentosa"> PT Citra Langgeng Sentosa</option>
                  <option value = "PT Iniled Indonesia Industri">PT Iniled Indonesia Industri</option>
                  <option value = "PT Artifa Sukses Persada">PT Artifa Sukses Persada</option>
                  <option value = "PT Infiniti Nuansa Internasional">PT Infiniti Nuansa Internasional</option>
            </select>       

       
            
            <label>Password: </label>
            <input  type="password"
                name = 'password'
                required
                className="form-control"
                value={this.state.password}
                onChange={this.onChangeField}
                />
            
            <label>Password (ulangi): </label>
            <input  type="password"
                name = 'password2'
                required
                className="form-control"
                value={this.state.password2}
                onChange={this.onChangeField}
                />
          </div>
          <div className="form-group">
            <input type="submit" value="Create User" className="btn btn-primary" />
          </div>
          <div className="form-group">
        {this.state.loading && (<><CircularProgress size={28}/>Loading...Mohon Tunggu</>)}
        </div>
        </form>
      </div>
    )
  }
}