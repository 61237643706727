import React, { Component } from 'react';
import axios from 'axios';
import auth from '../auth';
import Navbar from './navbar.component';
import config from '../keys.json';
import { CircularProgress } from '@material-ui/core';
import swal from 'sweetalert';
import jwt, { decode } from 'jsonwebtoken';

import urlCustom from '../url'
const apiUrl = urlCustom.getUrl(window)

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.onChangeField = this.onChangeField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    
  
    this.state = {
      nik: '',
      password: '',
      loading:false
    }
  }

  
  componentDidMount() {
    if (localStorage.getItem("TOKEN_KEY") != null) {
        return this.props.history.goBack();
     }
   }

  onChangeField(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
        [name]:value
    });
  }


  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token!=null) {
      const kirim = {
        tokens: token,
        keterangan:"xxx"
      };
      const decoded = jwt.verify(kirim.tokens,config.secret);
      return decoded;  
    }
  }

  async getkomplit(id){ 
    const hasil = await axios.get(apiUrl+"/users/"+id)
      .then(res => {
        this.setState({
          loading:false
        });
        if(res.data!=null){
          if(res.data.role=="adminairlabsakti" || res.data.role =="adminbatamnuansa" || res.data.role =='reviewer'){
            this.props.history.push("/dash")
            }
          else{
            this.props.history.push("/")
          }
      }
      else {
        alert('User tidak ditemukan, mohon login kembali');
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push('/login');
      }
      });
    }

  onSubmit(e) {
    e.preventDefault();

    const user = {
      nik: this.state.nik,
      password: this.state.password
    }
    this.setState({
      loading:true
    })
    axios.post(apiUrl+'/users/authenticate', user)
      .then(
          res => {
              localStorage.setItem("TOKEN_KEY",res.data.token);
              // alert('Login berhasil');
              swal("ISR","Login Sukses","success");
              const dariToken = this.parseTokenFunc();
                  if(dariToken!=null){
                    this.setState({
                      id:dariToken.sub
                    })
                    this.getkomplit(dariToken.sub);
                    // this.getBudget();
                  }
          }
      )
      .catch(err => {
        swal("Error","Error : "+err.response.data.message,"error");
        this.setState({
          loading:false
        })
      })
    this.setState({
      nik: '',
      password: '',
    })
  }

  render() {
    return (
      <div>
        <div>
        <Navbar/>
        </div>
        <h3>Login</h3>
        <form onSubmit={this.onSubmit}>
          <div className="form-group"> 
            <label>NIK: </label>
            <input  type="text"
                name = 'nik'
                required
                className="form-control"
                value={this.state.nik}
                onChange={this.onChangeField}
                />
            <label>Password: </label>
            <input  type="password"
                name = 'password'
                required
                className="form-control"
                value={this.state.password}
                onChange={this.onChangeField}
                />
           </div>
          <div className="form-group">
            <input type="submit" value="login" className="btn btn-primary" />
          </div>
        </form>
        <div>
        {this.state.loading && (<><CircularProgress/>Loading, mohon tunggu...</>)}
        </div>
      </div>
    )
  }
}