import React, { Component } from "react";
import axios from "axios";
import Navbar from "./navbar.component";
import config from "../keys.json";
import "react-datepicker/dist/react-datepicker.css";
import auth from "../auth";
import jwt, { decode } from "jsonwebtoken";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";
import xlsx from "xlsx";

import FormatUploadBudget from "../assets/FormatUploadBudget.JPG";
import ContohSmall from "../assets/Contoh-small.JPG";

import urlCustom from "../url";
const apiUrl = urlCustom.getUrl(window);

export default class BudgetAdd extends Component {
  constructor(props) {
    super(props);
    let todai = new Date();
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.state = {
      nik: "",
      nama: "",
      tgl: todai,
      periodCode: 0,
      ubt: 0,
      uip: 0,
      loading: false,
      loadingDate: false,
      komplit: [],
      bbt: 0,
      bbt_init: 0,
      bip: 0,
      bip_init: 0,
      tahun: "",
      id: "",
      perusahaan: "",
      selectedFile: null,
      uploadState: false,
      role: "",
      rolex: "",
      id: "",
    };
  }
  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token != null) {
      const kirim = {
        tokens: token,
        keterangan: "xxx",
      };
      // console.log(kirim);
      const decoded = jwt.verify(kirim.tokens, config.secret);
      // console.log(decoded)
      return decoded;
    }
  }

  goBack() {
    this.props.history.goBack();
  }
  componentDidMount() {
    this.getkomplit(this.props.match.params.id).then(() => {
      this.setState({
        loadingAwal: false,
      });
    });
    let stat = auth.isAuthenticated();
    this.setState({ isLoggedIn: stat });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var f = this.state.selectedFile;
    var reader = new FileReader();
    let impor = null;

    reader.onload = (e) => {
      var data = e.target.result;
      let teks = "";
      let flagWarning = false;
      let arrBudget = [];
      let readData = xlsx.read(data, { type: "binary" });
      const wsname = readData.SheetNames[0];
      impor = readData.Sheets[wsname];
      const dataParse = xlsx.utils.sheet_to_json(impor, { header: 1 });
      for (let i = 1; i < dataParse.length; i++) {
        const nik = dataParse[i][1].toString();
        const bbt_init = dataParse[i][2].toString();
        const bip_init = dataParse[i][3].toString();
        const bbt = bbt_init;
        const bip = bip_init;
        const periodCode = dataParse[i][4].toString();
        const tahun = dataParse[i][5].toString();
        arrBudget.push({
          nik,
          bbt_init,
          bip_init,
          bbt,
          bip,
          periodCode,
          tahun,
        });
      }
      console.log(arrBudget);
      axios.post(apiUrl + "/budget/addBulk", arrBudget).then((res) => {
        console.log(res);
        swal("ISR", "Upload Budget sukses", "success");
        this.setState({
          loading: false,
        });
      });
    };
    reader.onloadstart = () => {
      console.log("mulai upload");
      this.setState({
        uploadState: true,
      });
    };
    reader.readAsBinaryString(f);
  }

  onFileChange(event) {
    this.setState({
      selectedFile: event.target.files[0],
    });
    console.log(event.target.files[0]);
  }

  handleDownload() {
    // axios.get(apiUrl+"/uploads/BudgetUpload.xlsx")
    //   .then(res=>{
    //     console.log(res)
    //   })
    fetch(apiUrl + "/assets/BudgetUpload.xlsx").then((response) => {
      console.log(response);
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "BudgetUpload.xlsx";
        a.click();
      });
    });
  }

  async getkomplit(id) {
    const hasil = await axios.get(apiUrl + "/users/" + id).then((res) => {
      if (res != null) {
        this.setState({
          nik: res.data.nik,
          role: res.data.role,
          id: res.data.id,
          rolex: res.data.rolex,
        });
      } else {
        alert("User tidak ditemukan, mohon login kembali");
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push("/login");
      }
    });
  }

  render() {
    return (
      <div>
        <div>
          <Navbar
            userid={this.state.id}
            nik={this.state.nik}
            role={this.state.role}
            rolex={this.state.rolex}
          />
        </div>
        <div>
          <h3>{"Selamat datang, Admin"}</h3>
          Silahkan upload file excel budget sesuai format di bawah ini :
        </div>
        <div className="form-group">
          <img src={FormatUploadBudget} style={{ width: "100%" }} />
        </div>
        <div>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <input
                type="button"
                value="Download Format Budget (.xlsx)"
                className="btn btn-primary"
                style={{ marginRight: 20 }}
                onClick={this.handleDownload}
              />
            </div>
            <div className="form-group">
              <input
                type="file"
                name="file"
                className="btn btn-primary"
                onChange={this.onFileChange}
              />
            </div>
            <div className="form-group">
              <input
                type="submit"
                value="Submit Data"
                className="btn btn-primary"
                style={{ marginRight: 20 }}
              />
            </div>
          </form>
          <div>
            {this.state.loading && (
              <>
                <CircularProgress />
                Loading, mohon tunggu...
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
