import React, { Component } from "react";
import axios from "axios";
import Navbar from "./navbar.component";
import config from "../keys.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import auth from "../auth";
import { withRouter } from "react-router-dom";
import jwt, { decode } from "jsonwebtoken";
import { Grid} from "@material-ui/core";
import swal from "sweetalert";

import urlCustom from "../url";
const apiUrl = urlCustom.getUrl(window);

export default class ResetPass extends Component {
  constructor(props) {
    super(props);
    let todai = new Date();
    var per = todai.getMonth() + 1;
    this.stringPeriode = [
      "21 Desember-20 Januari",
      "21 Januari-20 Februari",
      "21 Februari-20 Maret",
      "21 Maret-20 April",
      "21 April-20 Mei",
      "21 Mei-20 Juni",
      "21 Juni-20 Juli",
      "21 Juli-20 Agustus",
      "21 Agustus-20 September",
      "21 September-20 Oktober",
      "21 Oktober-20 November",
      "21 November-20 Desember",
    ];
    this.onChangeField = this.onChangeField.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      periodCode:0,
      tahun:2021,
      division:"JKT",
      tanggalStart:new Date(),
      tanggalEnd:new Date(),
      id:"",
      role:"user",
      nik:"",
      nama:""
    };
  }
  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token != null) {
      const kirim = {
        tokens: token,
        keterangan: "xxx",
      };
      // console.log(kirim);
      const decoded = jwt.verify(kirim.tokens, config.secret);
      // console.log(decoded)
      return decoded;
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  async getkomplit(id) {
    const hasil = await axios.get(apiUrl + "/users/" + id).then((res) => {
      if (res != null) {
        this.setState({
          nik: res.data.nik,
          nama: res.data.nama,
          perusahaan: res.data.perusahaan,
          role: res.data.role,
        });
      } else {
        alert("User tidak ditemukan, mohon login kembali");
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push("/login");
      }
    });
  }

  componentDidMount() {
    let stat = auth.isAuthenticated();
    this.setState({ isLoggedIn: stat, id: this.props.match.params.id });
    this.getkomplit(this.props.match.params.id).then((res) => {});
  }
  onChangeField(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div>
        <div>
          <Navbar role={this.state.role} userid={this.state.id}/>
        </div>
        <Grid container style={{marginTop:'2vh',width:'10vw'}}>
          <Grid item container style={{marginTop:'1vh'}}>
            <Grid item xs={6}>
              Periode:
            </Grid>
            <Grid item xs={6}>
              <select onChange={(e)=>this.setState({...this.state,periodCode:this.stringPeriode.indexOf(e.target.value)})}>
                {this.stringPeriode.map(opsi=>{
                  return(
                    <option value={opsi}>{opsi}</option>  
                )})}
                </select>
            </Grid>
          </Grid>
          <Grid item container style={{marginTop:'1vh'}}>
          <Grid item xs={6}>
            Date From :
            </Grid>
            <Grid item xs={6}>
            <DatePicker
              selected={this.state.tanggalStart}
              onChange={(tgl)=>this.setState({tanggalStart:tgl})}
            /> 
            </Grid>
          </Grid>
          <Grid item container style={{marginTop:'1vh'}}>
          <Grid item xs={6}>
            Date To : 
            </Grid>
            <Grid item xs={6}>
            <DatePicker
              selected={this.state.tanggalEnd}
              onChange={(tgl)=>this.setState({tanggalEnd:tgl})}
            />
            </Grid>
          </Grid>
          <Grid item container style={{marginTop:'1vh'}}>
            <Grid item xs={6}>
            Tahun : 
            </Grid>
            <Grid item xs={6}>
          <input value={this.state.tahun} onChange={(e)=>this.setState({...this.state,tahun:Number(e.target.value)})}/>
          </Grid>
          </Grid>
          <Grid item container style={{marginTop:'1vh'}}>
            <Grid item xs={6}>
            Dvision : 
            </Grid>
            <Grid item xs={6}>
          <select onChange={(e)=>this.setState({...this.state,division:e.target.value})}>
                <option>JKT</option>
                <option>NON-JKT</option>
          </select>
          </Grid>
          </Grid>
          <Grid item container style={{marginTop:'1vh'}}>
          <button onClick={()=>{
            axios.post(apiUrl+"/expense/reportSOFIxls",this.state)
              .then(hsl=>{
                fetch(apiUrl + "/uploads/"+hsl.data+".xls").then((response) => {
                  console.log(response);
                  response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = hsl.data+".xls";
                    a.click();
                  });
                });
              })
              .catch(err=>{console.log(err)})
          }}>Export .xls</button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
