import React, { Component } from "react";
import axios from "axios";
import Navbar from "./navbar.component";
import config from "../keys.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import auth from "../auth";
import { withRouter } from "react-router-dom";
import jwt, { decode } from "jsonwebtoken";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";

import urlCustom from "../url";
const apiUrl = urlCustom.getUrl(window);

export default class BudgetEdit extends Component {
  constructor(props) {
    super(props);
    let todai = new Date();
    var per = todai.getMonth() + 1;

    this.onChangeField = this.onChangeField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      nik: "",
      nama: "",
      tgl: todai,
      periodCode: 0,
      ubt: 0,
      uip: 0,
      loading: false,
      loadingDate: false,
      komplit: [],
      bbt: 0,
      bbt_init: 0,
      bip: 0,
      bip_init: 0,
      tahun: "",
      id: "",
      idAdm: "",
      perusahaan: "",
    };
  }
  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token != null) {
      const kirim = {
        tokens: token,
        keterangan: "xxx",
      };
      // console.log(kirim);
      const decoded = jwt.verify(kirim.tokens, config.secret);
      // console.log(decoded)
      return decoded;
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  async getBudget(id) {
    await axios.get(apiUrl + "/budget/" + id).then((res) => {
      console.log(res.data);
      const stringPeriode = [
        "21 Desember-20 Januari",
        "21 Januari-20 Februari",
        "21 Februari-20 Maret",
        "21 Maret-20 April",
        "21 April-20 Mei",
        "21 Mei-20 Juni",
        "21 Juni-20 Juli",
        "21 Juli-20 Agustus",
        "21 Agustus-20 September",
        "21 September-20 Oktober",
        "21 Oktober-20 November",
        "21 November-20 Desember",
      ];
      if (res.data == "Budget not found") {
        swal(
          "Error",
          "Budget belum dimasukkan, silahkan hubungi admin",
          "error"
        );
        this.setState({
          loading: false,
          loadingdate: false,
          bbt: 0,
          bip: 0,
          bbt_init: 0,
          bip_init: 0,
        });
      } else {
        this.setState({
          nik: res.data.nik,
          bbt: res.data.bbt,
          bip: res.data.bip,
          bbt_init: res.data.bbt_init,
          bip_init: res.data.bip_init,
          periodString: stringPeriode[res.data.periodCode],
          periodCode: res.data.periodCode,
          tahun: res.data.tahun,
          loading: false,
          loadingdate: false,
        });
      }
    });
  }
  componentDidMount() {
    let stat = auth.isAuthenticated();
    this.setState({
      isLoggedIn: stat,
      id: this.props.match.params.id,
      nama: this.props.match.params.nama,
      perusahaan: this.props.match.params.perusahaan,
      idAdm: this.props.match.params.idAdm,
    });
    this.getBudget(this.props.match.params.id);
  }
  onChangeField(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleCheck() {
    this.setState({
      loading: true,
    });
    this.getBudget();
  }
  onSubmit(e) {
    e.preventDefault();
    if (Number(this.state.ubt) == 0 || Number(this.state.uip) == 0) {
      swal("Error", "Update Budget harus diisi", "error");
    } else {
      this.setState({
        loading: true,
      });
      const req = {
        filter1: this.state.nik,
        filter2: this.state.periodCode,
        filter3: Number(this.state.tahun),
        filterField1: "nik",
        filterField2: "periodCode",
        filterField3: "tahun",
      };
      console.log(req);
      axios.post(apiUrl + "/expense/aggregateExpense", req).then((res) => {
        console.log(res.data[0]);
        var tbt = 0;
        var tip = 0;
        if (res.data[0] != undefined) {
          tbt = res.data[0].sumTbt;
          tip = res.data[0].sumTip;
        } else {
          tbt = 0;
          tip = 0;
        }
        const bbt_init = this.state.ubt.replace(/,/g, "");
        const bip_init = this.state.uip.replace(/,/g, "");
        const bbt = bbt_init - tbt;
        const bip = bip_init - tip;
        const id = this.state.id;
        if (bbt < 0 || bip < 0) {
          swal("Error", "Sisa Budget Negatif", "error");
          this.setState({
            loading: false,
            ubt: 0,
            uip: 0,
          });
        } else {
          const req = {
            bbt_init,
            bip_init,
            bbt,
            bip,
            id,
          };
          console.log(req);
          axios.post(apiUrl + "/budget/update", req).then((res) => {
            this.setState({
              loading: false,
              ubt: 0,
              uip: 0,
            });
            swal("ISR", "Update Budget Sukses", "success").then((res) => {
              this.goBack();
            });
          });
        } //end else 2
      });
    } //end else
  }

  render() {
    return (
      <div>
        <div>
          <Navbar role="adminairlabsakti" userid={this.state.idAdm} />
        </div>
        <div>
          <h3>{"Selamat datang, Admin"}</h3>
          BUDGET EDIT - ADMIN
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>NIK: </label>
              <input
                type="text"
                name="nik"
                required
                className="form-control"
                value={this.state.nik}
                onChange={this.onChangeField}
                disabled
              />
              <label>Nama: </label>
              <input
                type="text"
                name="nama"
                required
                className="form-control"
                value={this.state.nama}
                onChange={this.onChangeField}
                disabled
              />
              <label>Perusahaan: </label>
              <input
                type="text"
                name="perusahaan"
                required
                className="form-control"
                value={this.state.perusahaan}
                onChange={this.onChangeField}
                disabled
              />
              {/* <label>{"Budget Awal BBM & Tol"}: </label>
                {this.state.loadingDate && (<><CircularProgress/>Loading...</>)}
             <NumberFormat className = "form-control"
                 thousandSeparator={true} 
                 prefix={''} 
                 name = "bbt_init" 
                 onChange = {this.onChangeField} 
                 value = {this.state.bbt_init} disabled/>      */}
              <label>{"Budget Awal"}: </label>
              {this.state.loadingDate && (
                <>
                  <CircularProgress />
                  Loading...
                </>
              )}
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={""}
                name="bip_init"
                onChange={this.onChangeField}
                value={this.state.bip_init}
                disabled
              />
              {/* <label>{"Sisa Budget"}: </label>
              {this.state.loadingDate && (
                <>
                  <CircularProgress />
                  Loading...
                </>
              )}
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={""}
                name="bbt"
                onChange={this.onChangeField}
                value={this.state.bbt}
                disabled
              /> */}
              <label>{"Sisa Budget"}: </label>
              {this.state.loadingDate && (
                <>
                  <CircularProgress />
                  Loading...
                </>
              )}
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={""}
                name="bip"
                onChange={this.onChangeField}
                value={this.state.bip}
                disabled
              />
              <label>Periode : </label>
              {this.state.loadingDate && (
                <>
                  <CircularProgress />
                  Loading...
                </>
              )}
              <input
                type="text"
                name="periodString"
                required
                className="form-control"
                value={this.state.periodString}
                onChange={this.onChangeField}
                disabled
              />
              {/* <label>{"Update Budget BBM & Tol"}: </label>
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={""}
                name="ubt"
                onChange={this.onChangeField}
                value={this.state.ubt}
                required
              /> */}

              <label>{"Update Budget"}: </label>
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={""}
                name="uip"
                onChange={this.onChangeField}
                value={this.state.uip}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="submit"
                value="Submit Data"
                className="btn btn-primary"
              />
            </div>
          </form>
          <div>
            {this.state.loading && (
              <>
                <CircularProgress />
                Loading, mohon tunggu...
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
