import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Navbar from "./navbar.component";
import config from "../keys.json";
import axios from "axios";
import { CircularProgress, Box, useRadioGroup } from "@material-ui/core";
import CustomToolbarSelect from "./custom-datatable-select-toolbar";
import CustomToolbar from "./custom-datatable-toolbar";

import urlCustom from "../url";
const apiUrl = urlCustom.getUrl(window);

export default class BudgetList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      budgets: [],
      nik: "",
      loading: false,
      id: "",
      loadingAwal: true,
      role: "",
      rolex: "",
      users: [],
    };
  }
  componentDidMount() {
    this.getkomplit(this.props.match.params.id).then(() => {
      this.populateExpense();
      this.setState({
        loadingAwal: false,
      });
    });
  }

  async getkomplit(id) {
    const hasil = await axios.get(apiUrl + "/users/" + id).then((res) => {
      if (res != null) {
        this.setState({
          nik: res.data.nik,
          role: res.data.role,
          rolex: res.data.rolex,
          id: res.data.id,
          childID: res.data.childUser,
        });
      } else {
        alert("User tidak ditemukan, mohon login kembali");
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push("/login");
      }
    });
  }

  populateExpense() {
    axios
      .get(apiUrl + "/budget/")
      .then((response) => {
        let dataPopul = response.data;
        axios.get(apiUrl + "/users/").then((res) => {
          let dataUser = res.data;
          let dataFilter = [];
          dataPopul.forEach((data) => {
            dataUser.forEach((user) => {
              if (user.nik == data.nik) {
                data.nama = user.nama;
                data.perusahaan = user.perusahaan;
              }
            });
            if (
              this.state.role == "adminairlabsakti" &&
              (this.state.rolex == "superadminsakti" ||
                this.state.childID.includes(data.nik))
            ) {
              dataFilter.push(data);
            } else if (this.state.role == "adminbatamnuansa") {
              if (data.perusahaan == "PT Infiniti Nuansa Internasional") {
                dataFilter.push(data);
              }
            }
          });
          this.setState({
            budgets: dataFilter,
            users: dataUser,
          });
          console.log(dataFilter);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleClick(data) {
    console.log(data);
    this.props.history.push(
      "/edit/budget/" +
        data[0] +
        "/" +
        data[2] +
        "/" +
        data[3] +
        "/" +
        this.state.id
    );
  }

  render() {
    const isAdmin =
      this.state.role == "adminairlabsakti" ||
      this.state.role == "adminbatamnuansa";
    const isAdminBatam = this.state.role == "adminbatamnuansa";
    const columns = [
      {
        name: "_id",
        options: {
          display: false,
          filter: false,
        },
      },
      {
        name: "nik",
        label: "NIK",
        options: {
          display: isAdmin,
        },
      },
      {
        name: "nama",
        label: "Nama",
        options: {
          display: isAdmin,
        },
      },
      {
        name: "perusahaan",
        label: "Perusahaan",
        options: {
          display: isAdmin,
        },
      },
      // {
      //   name:"bbt_init",
      //   label:'Budget Tunjangan BBM & Tol',
      //   options:{
      //   customBodyRender:(value,tableMeta,updateValue)=>{
      //     const nf= new Intl.NumberFormat('en-US').format(value);
      //     return nf;
      //   },
      //   filter:false
      // },
      // },
      {
        name: "bip_init",
        label: "Budget awal",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("en-US").format(value);
            return nf;
          },
          filter: false,
        },
      },
      // {
      //   name: "bbt",
      //   label: "Sisa Tunjangan BBM & Tol",
      //   options: {
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       const nf = new Intl.NumberFormat("en-US").format(value);
      //       return nf;
      //     },
      //     filter: false,
      //   },
      // },
      {
        name: "bip",
        label: "Sisa Budget",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("en-US").format(value);
            return nf;
          },
          filter: false,
        },
      },
      {
        name: "periodCode",
        label: "Periode",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const stringPeriode = [
              "21 Desember-20 Januari",
              "21 Januari-20 Februari",
              "21 Februari-20 Maret",
              "21 Maret-20 April",
              "21 April-20 Mei",
              "21 Mei-20 Juni",
              "21 Juni-20 Juli",
              "21 Juli-20 Agustus",
              "21 Agustus-20 September",
              "21 September-20 Oktober",
              "21 Oktober-20 November",
              "21 November-20 Desember",
            ];
            return stringPeriode[value - 1];
          },
        },
      },
      {
        name: "tahun",
        label: "Tahun",
      },
    ];
    const options = {
      viewColumns: false,
      print: false,
      filter: isAdmin,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          page="budget/"
        />
      ),
      customToolbar: () => {
        return <CustomToolbar />;
      },
      onRowClick: (rowData, rowState) => {
        this.handleClick(rowData);
      },
    };
    return (
      <div>
        <div>
          <Navbar
            userid={this.state.id}
            nik={this.state.nik}
            role={this.state.role}
            rolex={this.state.rolex}
          />
        </div>
        {this.state.loadingAwal && (
          <>
            <CircularProgress size={28} />
            Loading...Mohon Tunggu
          </>
        )}
        {!this.state.loadingAwal && (
          <>
            <MUIDataTable
              title={"Employee Budget List"}
              columns={columns}
              data={this.state.budgets}
              options={options}
            />
          </>
        )}
      </div>
    );
  }
}
