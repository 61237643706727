import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Navbar from "./navbar.component";
import config from "../keys.json";
import swal from "sweetalert";
import axios from "axios";
import { CircularProgress, Box, Modal, Button, Input } from "@material-ui/core";
import CustomToolbarSelect from "./custom-datatable-select-toolbar";
import ModalImage from "react-modal-image";

import urlCustom from "../url";
const apiUrl = urlCustom.getUrl(window);

export default class ExpenseList extends Component {
  constructor(props) {
    super(props);

    // this.handleApprove = this.handleApprove.bind(this);
    // this.handleReject = this.handleReject.bind(this);

    this.state = {
      expenses: [],
      nik: "",
      nama: "",
      tgl: "",
      loading: false,
      id: "",
      loadingAwal: true,
      role: "",
      rolex: "",
      childID: [],
    };
  }
  componentDidMount() {
    this.getkomplit(this.props.match.params.id).then(() => {
      this.populateExpense();
      this.setState({
        loadingAwal: false,
      });
    });
  }

  async getkomplit(id) {
    const hasil = await axios.get(apiUrl + "/users/" + id).then((res) => {
      if (res != null) {
        const dddd = new Date();
        let ddd = dddd.toString().split("GMT")[0];
        this.setState({
          nik: res.data.nik,
          nama: res.data.nama,
          role: res.data.role,
          rolex: res.data.rolex,
          tgl: ddd,
          id: res.data.id,
          childID: res.data.childUser,
        });
      } else {
        alert("User tidak ditemukan, mohon login kembali");
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push("/login");
      }
    });
  }

  handleApprove(rowData) {
    console.log(rowData);
    swal({
      text: "Masukkan remark : ",
      content: "input",
      button: {
        text: "Approve",
        closeModal: false,
      },
    }).then((remarks) => {
      if (!remarks) {
        swal("Error", "Remark harus diisi", "error");
      } else {
        const req = {
          status: "Approved by " + this.state.nama + " on " + this.state.tgl,
          remark: remarks,
          acc: "Approved",
        };
        axios
          .post(apiUrl + "/expense/update/" + rowData.rowData[0], req)
          .then(() => {
            this.setState({
              loading: true,
            });
            const req = {
              filter1: rowData.rowData[1],
              filter2: rowData.rowData[11],
              filter3: rowData.rowData[12],
              filterField1: "nik",
              filterField2: "periodCode",
              filterField3: "tahun",
            };
            console.log(req);
            axios
              .post(apiUrl + "/expense/aggregateExpense", req)
              .then((res) => {
                // console.log(res.data[0]);
                // console.log(req);
                var sumTbt = 0;
                var sumTip = 0;
                if (res.data[0] != undefined) {
                  sumTbt = res.data[0].sumTbt;
                  sumTip = res.data[0].sumTip;
                } else {
                  sumTbt = 0;
                  sumTip = 0;
                }
                axios.post(apiUrl + "/budget/find", req).then((res) => {
                  console.log(res.data.budgets);
                  const bbt_init = res.data.budgets.bbt_init;
                  const bip_init = res.data.budgets.bip_init;
                  const bbt = bbt_init;
                  var bip;
                  if (rowData.rowData[16] == "user") {
                    bip = bip_init - sumTip - sumTbt;
                  } else {
                    bip = bip_init - sumTip;
                  }
                  const id = res.data.budgets._id;
                  const kirim = {
                    bbt_init,
                    bip_init,
                    bbt,
                    bip,
                    id,
                  };
                  if (bip >= 0) {
                    axios.post(apiUrl + "/budget/update", kirim).then((res) => {
                      console.log(res);
                      const sisaBbt = res.data.bbt;
                      const sisaBip = res.data.bip;
                      const teks =
                        "Approve sukses !\n" +
                        // sisaBbt +
                        "Sisa Budget : " +
                        sisaBip;
                      swal("ISR", teks, "success").then((hasil) => {
                        window.location.reload(true);
                      });
                    });
                  } else {
                    const req = {
                      status: "Pending",
                      remarks: "Menunggu Approve Admin",
                      acc: "Pending",
                    };
                    axios
                      .post(
                        apiUrl + "/expense/update/" + rowData.rowData[0],
                        req
                      )
                      .then(() => {
                        swal("Error", "Budget sudah habis", "error").then(
                          (hasil) => {
                            window.location.reload(true);
                          }
                        );
                      });
                  }
                });
              });
          });
      }
    });
  }

  handleReject(rowData) {
    console.log(rowData);
    swal({
      text: "Masukkan remark : ",
      content: "input",
      button: {
        text: "Reject",
        closeModal: false,
      },
    }).then((remarks) => {
      if (!remarks) {
        swal("Error", "Remark harus diisi", "error");
      } else {
        const req = {
          status: "Rejected by " + this.state.nama + " on " + this.state.tgl,
          remark: remarks,
          acc: "Rejected",
        };
        axios
          .post(apiUrl + "/expense/update/" + rowData.rowData[0], req)
          .then(() => {
            this.setState({
              loading: true,
            });
            const req = {
              filter1: rowData.rowData[1],
              filter2: rowData.rowData[11],
              filter3: rowData.rowData[12],
              filterField1: "nik",
              filterField2: "periodCode",
              filterField3: "tahun",
            };
            console.log(req);
            axios
              .post(apiUrl + "/expense/aggregateExpense", req)
              .then((res) => {
                // console.log(res.data[0]);
                // console.log(req);
                var sumTbt = 0;
                var sumTip = 0;
                if (res.data[0] != undefined) {
                  sumTbt = res.data[0].sumTbt;
                  sumTip = res.data[0].sumTip;
                } else {
                  sumTbt = 0;
                  sumTip = 0;
                }
                axios.post(apiUrl + "/budget/find", req).then((res) => {
                  console.log(res.data.budgets);
                  const bbt_init = res.data.budgets.bbt_init;
                  const bip_init = res.data.budgets.bip_init;
                  const bbt = bbt_init;
                  var bip;
                  if (rowData.rowData[16] == "user") {
                    bip = bip_init - sumTip - sumTbt;
                  } else {
                    bip = bip_init - sumTip;
                  }
                  const id = res.data.budgets._id;
                  const kirim = {
                    bbt_init,
                    bip_init,
                    bbt,
                    bip,
                    id,
                  };
                  axios.post(apiUrl + "/budget/update", kirim).then((res) => {
                    console.log(res);
                    const sisaBbt = res.data.bbt;
                    const sisaBip = res.data.bip;
                    const teks =
                      "Reject sukses !\n" +
                      // sisaBbt +
                      "Sisa Budget : " +
                      sisaBip;
                    swal("ISR", teks, "success").then((hasil) => {
                      window.location.reload(true);
                    });
                  });
                });
              });
          });
      }
    });
  }

  recalculateBudget(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const req = {
      filter1: this.state.nik,
      filter2: this.state.periodCode,
      filter3: Number(this.state.tahun),
      filterField1: "nik",
      filterField2: "periodCode",
      filterField3: "tahun",
    };
    console.log(req);
    axios.post(apiUrl + "/expense/aggregateExpense", req).then((res) => {
      console.log(res.data[0]);
      const bbt_init = this.state.ubt.replace(/,/g, "");
      const bip_init = this.state.uip.replace(/,/g, "");
      const bbt = bbt_init - res.data[0].sumTbt;
      const bip = bip_init - res.data[0].sumTip;
      const id = this.state.id;
      if (bbt < 0 || bip < 0) {
        swal("Error", "Sisa Budget Negatif", "error");
        this.setState({
          loading: false,
          ubt: 0,
          uip: 0,
        });
      } else {
        const req = {
          bbt_init,
          bip_init,
          bbt,
          bip,
          id,
        };
        axios.post(apiUrl + "/budget/update", req).then((res) => {
          this.setState({
            loading: false,
            ubt: 0,
            uip: 0,
          });
          swal("ISR", "Update Budget Sukses", "success").then((res) => {
            this.goBack();
          });
        });
      } //end else 2
    });
  }

  populateExpense() {
    axios
      .get(apiUrl + "/expense/")
      .then((response) => {
        let dataPopul = response.data;
        var dataFilter = [];
        const nik = this.state.nik;
        if (
          this.state.role == "adminairlabsakti" ||
          this.state.role == "adminbatamnuansa" ||
          this.state.role == "reviewer"
        ) {
          axios.get(apiUrl + "/users/").then((res) => {
            let dataUser = res.data;
            let dataFilter = [];
            dataPopul.forEach((data) => {
              dataUser.forEach((user) => {
                if (user.nik == data.nik) {
                  data.nama = user.nama;
                  data.perusahaan = user.perusahaan;
                  data.role = user.role;
                }
              });
              if (
                (this.state.role == "adminairlabsakti" ||
                  this.state.role == "reviewer") &&
                (this.state.rolex == "superadminsakti" ||
                  this.state.childID.includes(data.nik))
              ) {
                dataFilter.push(data);
              } else if (this.state.role == "adminbatamnuansa") {
                if (data.perusahaan == "PT INFINITI NUANSA INTERNATIONA") {
                  dataFilter.push(data);
                }
              }
            });
            this.setState({
              expenses: dataFilter,
            });
          });
        } else {
          for (let index = 0; index < dataPopul.length; index++) {
            if (dataPopul[index].nik == nik) {
              dataFilter.push(dataPopul[index]);
            }
          }
          this.setState({
            expenses: dataFilter,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const isAdmin =
      this.state.role == "adminairlabsakti" ||
      this.state.role == "adminbatamnuansa" ||
      this.state.role == "reviewer";
    const columns = [
      {
        //0
        name: "_id",
        options: {
          display: false,
          filter: false,
          download: false,
        },
      },
      {
        //1
        name: "nik",
        label: "NIK",
        options: {
          display: isAdmin,
        },
      },
      {
        //2
        name: "nama",
        label: "Nama",
        options: {
          display: isAdmin,
        },
      },
      {
        //3
        name: "perusahaan",
        label: "Perusahaan",
        options: {
          display: isAdmin,
        },
      },
      {
        //4
        name: "tbt",
        label: "Input Tunjangan BBM & Tol",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("en-US").format(value);
            return nf;
          },
          filter: false,
        },
      },
      {
        //5
        name: "tip",
        label: "Input Tunjangan Internet & Pulsa",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("en-US").format(value);
            return nf;
          },
          filter: false,
        },
      },
      {
        //6
        name: "speedo",
        label: "Input Speedometer",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("en-US").format(value);
            return nf;
          },
          filter: false,
        },
      },
      {
        //7
        name: "selisih",
        label: "Selisih Speedometer (km)",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("en-US").format(value);
            return nf;
          },
          filter: false,
        },
      },
      {
        //8
        name: "tll",
        label: "Tunjangan lain-lain",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = new Intl.NumberFormat("en-US").format(value);
            return nf;
          },
          filter: false,
        },
      },
      {
        //9
        name: "createdAt",
        label: "Tanggal Input",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const nf = value.split("T");
            return nf[0];
          },
        },
      },
      {
        //10
        name: "namaFile",
        label: "Uploaded File",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return value.map((values) => {
              return (
                <div>
                  <ModalImage
                    alt={values.split("/")[1]}
                    large={apiUrl + "/" + values}
                  />
                </div>
              );
            });
          },
          filter: false,
        },
      },
      {
        //11
        name: "periodCode",
        options: {
          display: false,
          download: false,
        },
      },
      {
        //12
        name: "tahun",
        label: "Tahun",
        options: {
          display: false,
          download: false,
        },
      },
      { //13
        name: "status",
        label: "Status",
      },
      { //14
        name: "remark",
        label: "Remark",
      },
      { //15
        name: "acc",
        label: "Action",
        options: {
          display: isAdmin,
          filter: false,
          download: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return ( ((value!="Approved" && value!="Rejected") || this.state.role=="reviewer") &&
              <div>
                <button
                  style={{ marginBottom: 20 }}
                  onClick={() => {
                    this.handleApprove(tableMeta);
                  }}
                >
                  Approve
                </button>
                <button
                  style={{ marginBottom: 20 }}
                  onClick={() => {
                    this.handleReject(tableMeta);
                  }}
                >
                  Reject
                </button>
              </div>
            );
          },
        },
      },
      { //16
        name: "role",
        label: "Role",
        options: {
          display: false,
        },
      },
    ];
    const options = {
      viewColumns: false,
      print: false,
      filter: isAdmin,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          displayData={displayData}
          setSelectedRows={setSelectedRows}
          page="expense/"
          role={this.state.role}
        />
      ),
      fixedHeader: true,
      responsive: "standard",
      // onDownload:(buildHead, buildBody, columns, data)=>{
      //   console.log(data.length)
      // }
    };
    return (
      <div>
        <div>
          <Navbar
            userid={this.state.id}
            nik={this.state.nik}
            role={this.state.role}
            rolex={this.state.rolex}
          />
        </div>
        <div style={{ height: "100vh" }}>
          {this.state.loadingAwal && (
            <>
              <CircularProgress size={28} />
              Loading...Mohon Tunggu
            </>
          )}
          {!this.state.loadingAwal && (
            <MUIDataTable
              title={"Employee Expense List"}
              columns={columns}
              data={this.state.expenses}
              options={options}
            />
          )}
        </div>
      </div>
    );
  }
}
