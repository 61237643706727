import React, { Component } from "react";
import axios from "axios";
import Navbar from "./navbar.component";
import config from "../keys.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import auth from "../auth";
import { withRouter } from "react-router-dom";
import jwt, { decode } from "jsonwebtoken";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";

import urlCustom from "../url";
const apiUrl = urlCustom.getUrl(window);

export default class ResetPass extends Component {
  constructor(props) {
    super(props);
    let todai = new Date();
    var per = todai.getMonth() + 1;
    this.onChangeField = this.onChangeField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      nik: "",
      nama: "",
      loading: false,
      loadingDate: false,
      password: "",
      password2: "",
      id: "",
      perusahaan: "",
      role: "",
    };
  }
  parseTokenFunc() {
    const token = localStorage.getItem("TOKEN_KEY");
    if (token != null) {
      const kirim = {
        tokens: token,
        keterangan: "xxx",
      };
      // console.log(kirim);
      const decoded = jwt.verify(kirim.tokens, config.secret);
      // console.log(decoded)
      return decoded;
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  async getkomplit(id) {
    const hasil = await axios.get(apiUrl + "/users/" + id).then((res) => {
      if (res != null) {
        this.setState({
          nik: res.data.nik,
          nama: res.data.nama,
          perusahaan: res.data.perusahaan,
          role: res.data.role,
        });
      } else {
        alert("User tidak ditemukan, mohon login kembali");
        localStorage.removeItem("TOKEN_KEY");
        this.props.history.push("/login");
      }
    });
  }

  componentDidMount() {
    let stat = auth.isAuthenticated();
    this.setState({ isLoggedIn: stat, id: this.props.match.params.id });
    this.getkomplit(this.props.match.params.id).then((res) => {});
  }
  onChangeField(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    if (this.state.password != this.state.password2) {
      swal("Error", "Password tidak sama", "error");
      this.setState({
        loading: false,
      });
    } else {
      const req = {
        id: this.state.id,
        newPassword: this.state.password,
      };
      axios.post(apiUrl + "/users/update", req).then(() => {
        this.setState({
          loading: false,
        });
        swal("ISR", "Password telah diganti", "success").then((hasil) => {
          this.goBack();
        });
      });
    }
  }
  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div>
        <div>
          <Navbar role={this.state.role} />
        </div>
        <div>
          <h3>{"Selamat datang, Admin"}</h3>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>NIK: </label>
              <input
                type="text"
                name="nik"
                required
                className="form-control"
                value={this.state.nik}
                onChange={this.onChangeField}
                disabled
              />
              <label>Nama: </label>
              <input
                type="text"
                name="nama"
                required
                className="form-control"
                value={this.state.nama}
                onChange={this.onChangeField}
                disabled
              />
              <label>Perusahaan: </label>
              <input
                type="text"
                name="perusahaan"
                required
                className="form-control"
                value={this.state.perusahaan}
                onChange={this.onChangeField}
                disabled
              />
              <label>Password Baru: </label>
              <input
                type="password"
                name="password"
                required
                className="form-control"
                value={this.state.password}
                onChange={this.onChangeField}
              />
              <label>Password Baru (Ulangi): </label>
              <input
                type="password"
                name="password2"
                required
                className="form-control"
                value={this.state.password2}
                onChange={this.onChangeField}
              />
            </div>
            <div className="form-group">
              <input
                type="submit"
                value="Ganti Password"
                className="btn btn-primary"
              />
            </div>
          </form>
          <div>
            {this.state.loading && (
              <>
                <CircularProgress />
                Loading, mohon tunggu...
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
