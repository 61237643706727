import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter as Router,Route} from "react-router-dom"
import {Switch} from "react-router-dom"
import CreateUser from "./components/create-user.component";
import {ProtectedRoute} from './protectedRoute.js';
import Login from './components/Login';
import BudgetEdit from './components/edit-budget';
import BudgetList from './components/budget-list';
import ExpenseList from './components/expense-list';
import UserList from './components/user-list';
import CreateExpense from './components/create-expense';
import BudgetAdd from './components/add-budget';
import ResetPass from './components/reset-pass';
import Dashboard from './components/dashboard'
import bg from './assets/Contoh.JPG';
import ReportSofi from './components/reportSofi'
import GroupUser from './components/user-grouping';

function App() {
  return (
      <div style={{backgroundImage:{bg},width:'100hw',paddingLeft:'5%',paddingRight:'5%'}}>
      <br/>   
      
      <Switch>
      <ProtectedRoute exact path="/" component={CreateExpense} />
      <ProtectedRoute exact path="/reportSofi/:id" component={ReportSofi} />
      <ProtectedRoute exact path="/dash" component={Dashboard} />
      <ProtectedRoute exact path="/list/budget/:id" component={BudgetList} />
      <ProtectedRoute exact path="/usergroup/:id" component={GroupUser} />
      <ProtectedRoute exact path="/list/user/:id" component={UserList} />
      <ProtectedRoute exact path="/list/expense/:id" component={ExpenseList} />
      <ProtectedRoute exact path="/edit/budget/:id/:nama/:perusahaan/:idAdm" component={BudgetEdit} />
      <ProtectedRoute exact path="/passres/:id" component={ResetPass} />
      <ProtectedRoute exact path="/add/budget/:id" component={BudgetAdd} />
      <Route path="/login" component={Login}/>
      {/* <Route path="/register" component={CreateUser}/> */}
      <Route path="*" component={() => "404 NOT FOUND"} />
      </Switch>
      </div>
    
  );
}

export default App;
