import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from "@material-ui/core/styles";
import config from '../keys.json';
import swal from 'sweetalert';
import axios from 'axios';


import urlCustom from '../url'
const apiUrl = urlCustom.getUrl(window)

const defaultToolbarSelectStyles = {
  iconButton: {
  },
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
};


class CustomToolbarSelect extends React.Component {
  
  handleClickInverseSelection = () => {
    const nextSelectedRows = this.props.displayData.reduce((nextSelectedRows, _, index) => {
      if (!this.props.selectedRows.data.find(selectedRow => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }
      return nextSelectedRows;
    }, []);
    this.props.setSelectedRows(nextSelectedRows);
  };

  handleClickDeselectAll = () => {
    this.props.setSelectedRows([]);
  };

  handleDelete = () => {
    // console.log(this.props.selectedRows.data);
    
    const daftarData = this.props.selectedRows.data;
    swal({
        title: "Konfirmasi",
        text: "Yakin akan menghapus data ini ?",
        icon: "warning",
        buttons: true,
        })
        .then((hasil)=>{
        if(hasil){
        daftarData.forEach(datahapus => {
            let idx = datahapus.index;
            let displayData = this.props.displayData;
            // console.log(displayData[idx]);
            let id = displayData[idx].data[0];
            let nik = displayData[idx].data[1];
            let periodCode = displayData[idx].data[8];
            let tahun = displayData[idx].data[9];
            axios.delete(apiUrl+'/'+this.props.page+id)
             .then(response => { 
              const req = {
                filter1: nik,
                filter2: periodCode,
                filter3: tahun,
                filterField1: "nik",
                filterField2: "periodCode",
                filterField3: "tahun"
              }
              // console.log(req)
              axios.post(apiUrl+'/expense/aggregateExpense',req)
                .then((res)=>{
                  console.log(res.data[0])
                  var sumTbt=0;
                  var sumTip=0;
                  if(res.data[0]!=undefined){
                    sumTbt = res.data[0].sumTbt;
                    sumTip = res.data[0].sumTip;
                  }
                  else {
                    sumTbt = 0;
                    sumTip = 0;
                  }
                  const req = {
                    filter1: nik,
                    filter2: periodCode,
                    filter3: tahun,
                    filterField1: "nik",
                    filterField2: "periodCode",
                    filterField3: "tahun"
                  }
                  axios.post(apiUrl+'/budget/find',req)
                    .then((res)=>{
                      console.log(res)
                      const bbt_init=res.data.budgets.bbt_init;
                      const bip_init=res.data.budgets.bip_init;
                      const id=res.data.budgets._id;
                      const bbt = bbt_init-sumTbt;
                      const bip = bip_init-sumTip;
                      const req ={
                          bbt_init,
                          bip_init,
                          bbt,
                          bip,
                          id
                      }
                      axios.post(apiUrl+'/budget/update',req)
                      .then((res)=>{
                          console.log(res)
                          const sisaBbt = res.data.bbt;
                          const sisaBip = res.data.bip;
                          const teks = "Delete sukses !\nSisa Budget Tol & BBM : "+sisaBbt+"\nSisa Budget Internet & Pulsa : "+sisaBip
                          swal("ISR",teks,"success")
                          .then((hasil)=>
                            {window.location.reload(true)}
                          )
                      })
                    })
                })
           });
        }); 
        }
      },
     )
  };
  handleDeleteBudget = () => {
    // console.log(this.props.selectedRows.data);
    const daftarData = this.props.selectedRows.data;
    swal({
        title: "Konfirmasi",
        text: "Yakin akan menghapus data ini ?",
        icon: "warning",
        buttons: true,
        })
        .then((hasil)=>{
        if(hasil){
        daftarData.forEach(datahapus => {
            let idx = datahapus.index;
            let displayData = this.props.displayData;
            // console.log(displayData[idx]);
            let id = displayData[idx].data[0];
            let nik = displayData[idx].data[1];
            let periodCode = displayData[idx].data[8];
            let tahun = displayData[idx].data[9];
            axios.delete(apiUrl+'/'+this.props.page+id)
               .then(response => { 
                  swal("ISR","Delete Budget Sukses","success")
                  window.location.reload(true)        
           });
        }); 
        }
      },
     )
  };

  handleDeleteChild = () =>{
    // console.log(this.props.displayData)
    console.log(this.props.selectedRows.data)
    let idxTerpilih = this.props.selectedRows.data.map((x)=>{
      return x.index
    })
    // console.log(idxTerpilih)
    let currentChild = this.props.displayData.map((x)=>{
      return x.data[1]
    });
    let updateChild=[]
    // console.log(currentChild);
    currentChild.forEach((data,idx) => {
      if(!idxTerpilih.includes(idx)){
        updateChild.push(data)
      }
    });
    // console.log(updateChild)
    const req = {
      id:this.props.id,
      childUser:updateChild
    }
    axios.post(apiUrl+'/users/updateChild',req)
      .then(res=>{
        console.log(res)
        // window.location.reload(true)        
      })
  }

  handleAddChild = () =>{
    // console.log(this.props.displayData)
    // console.log(this.props.selectedRows.data)
    let idxTerpilih = this.props.selectedRows.data.map((x)=>{
      return x.index
    })
    let tambahan=[]
    idxTerpilih.forEach(data=>{
        tambahan.push(this.props.displayData[data].data[1])
    })
    // console.log(tambahan)
    let currentChild = this.props.childUser
    
    // console.log(currentChild)
    tambahan.forEach((data) => {
      if(!currentChild.includes(data)){
        currentChild.push(data)
      }
    });
    // console.log(currentChild);
    // console.log(updateChild)
    const req = {
      id:this.props.id,
      childUser:currentChild
    }
    axios.post(apiUrl+'/users/updateChild',req)
      .then(res=>{
        console.log(res)
        // window.location.reload(true)        
      })
  }
  handleClickBlockSelected = () => {
    console.log(`block users with dataIndexes: ${this.props.selectedRows.data.map(row => row.dataIndex)}`);
  };

  render() {
    const { classes } = this.props;
    const isAdmin = this.props.role;
    return (
      <div className={classes.iconContainer}>
        {/* <Tooltip title={"Deselect ALL"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickDeselectAll}>
            <IndeterminateCheckBoxIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Inverse selection"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickInverseSelection}>
            <CompareArrowsIcon className={[classes.icon, classes.inverseIcon].join(" ")} />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Block selected"}>
          <IconButton className={classes.iconButton} onClick={this.handleClickBlockSelected}>
            <BlockIcon className={classes.icon} />
          </IconButton>
        </Tooltip> */}
        {(this.props.page=='expense/') && (isAdmin=='adminairlabsakti') && <>
        <Tooltip title={"Delete Expense"}>
          <IconButton className={classes.iconButton} onClick={this.handleDelete}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        </>}
        {(this.props.page=='budget/') && <>
        <Tooltip title={"Delete Budget"}>
          <IconButton className={classes.iconButton} onClick={this.handleDeleteBudget}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        </>}
        {(this.props.page=='selectedUser') && <>
        <Tooltip title={"Remove Selected User"}>
          <IconButton className={classes.iconButton} onClick={this.handleDeleteChild}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        </>}
        {(this.props.page=='allUser') && <>
        <Tooltip title={"Add User to Admin"}>
          <IconButton className={classes.iconButton} onClick={this.handleAddChild}>
            <AddIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
        </>}
      </div>
      
    );
  }
}

export default withStyles(defaultToolbarSelectStyles, { name: "CustomToolbarSelect" })(CustomToolbarSelect);